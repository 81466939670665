"use client";
import { env } from '@/env.mjs';
import { useSession } from '@supabase/auth-helpers-react';

import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";

const posthogEnabled = env.NEXT_PUBLIC_POSTHOG_KEY && env.NEXT_PUBLIC_POSTHOG_HOST;

export default function PosthogIdentify() {
  const posthog = usePostHog();
  const session = useSession();

  useEffect(() => {
    if (!session) return;
    if (session.user && posthog) {
      posthog.identify(session.user.id, {
        email: session.user.email,
      });
      // if (session.user.teams?.length > 0) {
      //   posthog?.group("team", session.user.teams[0].id);
      // }
    }
  }, [session, posthog]);

  return null;
}
